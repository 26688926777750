var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"w-full absolute block z-50"},[_c('Header')],1),_vm._m(0),(!_vm.hasNewSeasonalPage)?_c('div',{staticClass:"w-screen offers-section-container offers-section-bg"},[_vm._m(1)]):_vm._e(),(_vm.hasNewSeasonalPage)?_c('div',{staticClass:"w-screen offers-section-container ll-seasonal-banner relative"}):_vm._e(),_vm._m(2),_c('div',{staticClass:"w-screen hidden offers-container lavish-special-offers-bg py-2"},[_c('div',{staticClass:"slider-container",attrs:{"id":"slider-container1"}},[_c('div',{staticClass:"slider",style:({ transform: `translateX(-${_vm.activeIndex * 100}%)` })},[_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"slide-indicators"},_vm._l((_vm.slides),function(slide,index){return _c('button',{key:index,class:{ active: _vm.activeIndex === index },on:{"click":function($event){return _vm.selectSlide(index)}}},[(_vm.activeIndex === index)?_c('img',{staticClass:"w-6 mobileL:w-8 lg:w-10 mb-4 mobileL:mb-10 lg:mb-16",attrs:{"src":require("../../assets/images/special-offers/shine.png"),"alt":"Selected Indicator"}}):_c('img',{staticClass:"w-6 mobileL:w-8 lg:w-10 mb-4 mobileL:mb-10 lg:mb-16",attrs:{"src":require("../../assets/images/special-offers/shine 2.png"),"alt":"Unselected Indicator"}})])}),0)]),_c('div',{staticClass:"w-full absolute block z-20"},[_c('Footer')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-screen offers-section-container space-top lavish-special-offers-banner"},[_c('div',{staticClass:"relative h-full w-full flex mobileL:items-end justify-center flex-col"},[_c('img',{staticClass:"absolute top-0 left-0 h-full z-5 -ml-36 object-cover overflow-x-visible mobileL:-ml-64 lg:-ml-36 xl:ml-20",attrs:{"src":require("../../assets/images/special-offers/special-offers-banner.png"),"alt":"Lavish Lashes Banner"}}),_c('div',{staticClass:"items-center flex justify-center z-10 text-center mobileL:mr-10 lg:mr-16 2xl:mr-36"},[_c('h1',{staticClass:"font-historia text-center text-4xl mobileL:text-6xl 2xl:text-8xl text-white-1 leading-none mobileL:mt-10 2xl:mt-16"},[_vm._v(" Special Offers ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full flex items-center flex-col z-10 text-center justify-center"},[_c('h1',{staticClass:"font-historia text-center text-4xl md:text-6xl 2xl:text-9xl text-white-1 leading-none m-auto"},[_c('span',{staticClass:"text-yellow-2"},[_vm._v("Lashes ")]),_vm._v(" soft"),_c('br'),_vm._v(" as silk ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-screen hidden relative offers-container lavish-special-offers-bg py-4 lg:py-2"},[_c('div',{staticClass:"flex justify-center items-center"},[_c('div',{staticClass:"flex flex-col items-center justify-center mobileL:flex-row"},[_c('img',{staticClass:"w-5/12 max-w-full h-auto py-4 mobileL:py-20 xl:py-36 mobileL:pr-10 mobileL:pl-20",attrs:{"src":require("../../assets/images/special-offers/glam-deal.png"),"alt":""}}),_c('img',{staticClass:"w-7/12 max-w-full h-auto py-4 mobileL:py-8 xl:py-24 mobileL:pl-10 mobileL:pr-20",attrs:{"src":require("../../assets/images/special-offers/student-glam-deal.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide"},[_c('div',{staticClass:"flex flex-col items-center justify-center mobileL:flex-row"},[_c('img',{staticClass:"w-5/12 max-w-full h-auto py-4 mobileL:py-20 xl:py-36 mobileL:pr-10 mobileL:pl-20",attrs:{"src":require("../../assets/images/special-offers/glam-deal.png"),"alt":""}}),_c('img',{staticClass:"w-7/12 max-w-full h-auto py-4 mobileL:py-8 xl:py-24 mobileL:pl-10 mobileL:pr-20",attrs:{"src":require("../../assets/images/special-offers/student-glam-deal.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide"},[_c('div',{staticClass:"flex flex-col items-center justify-center mobileL:flex-row"},[_c('img',{staticClass:"w-5/12 max-w-full h-auto py-4 mobileL:py-20 xl:py-36 mobileL:pr-10 mobileL:pl-20",attrs:{"src":require("../../assets/images/special-offers/glam-deal.png"),"alt":""}}),_c('img',{staticClass:"w-7/12 max-w-full h-auto py-4 mobileL:py-8 xl:py-24 mobileL:pl-10 mobileL:pr-20",attrs:{"src":require("../../assets/images/special-offers/student-glam-deal.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide"},[_c('div',{staticClass:"flex flex-col items-center justify-center mobileL:flex-row"},[_c('img',{staticClass:"w-5/12 max-w-full h-auto py-4 mobileL:py-20 xl:py-28 mobileL:pr-10 mobileL:pl-20",attrs:{"src":require("../../assets/images/special-offers/glam-deal.png"),"alt":""}}),_c('img',{staticClass:"w-7/12 max-w-full h-auto py-4 mobileL:py-8 xl:py-16 mobileL:pl-10 mobileL:pr-20",attrs:{"src":require("../../assets/images/special-offers/student-glam-deal.jpg"),"alt":""}})])])
}]

export { render, staticRenderFns }