<template>
  <div class="relative">
    <div class="w-full absolute block z-50">
      <Header/>
    </div>
    <div class="w-screen offers-section-container space-top lavish-special-offers-banner">
      <div class="relative h-full w-full flex mobileL:items-end justify-center flex-col">
        <img src="../../assets/images/special-offers/special-offers-banner.png" alt="Lavish Lashes Banner"
          class="absolute top-0 left-0 h-full z-5 -ml-36 object-cover overflow-x-visible mobileL:-ml-64 lg:-ml-36 xl:ml-20">
        <div class="items-center flex justify-center z-10 text-center mobileL:mr-10 lg:mr-16 2xl:mr-36 ">
          <h1 class="font-historia text-center text-4xl mobileL:text-6xl 
          2xl:text-8xl text-white-1 leading-none mobileL:mt-10 2xl:mt-16">
            Special Offers
          </h1>
        </div>
      </div>
    </div>

    <div class="w-screen offers-section-container offers-section-bg" v-if="!hasNewSeasonalPage">
      <div class="w-full h-full flex items-center flex-col z-10 text-center justify-center">
          <h1 class="font-historia text-center text-4xl md:text-6xl 2xl:text-9xl
          text-white-1 leading-none m-auto">
            <span class="text-yellow-2">Lashes </span> soft<br> as silk
          </h1>
      </div>
    </div>

    <div class="w-screen offers-section-container ll-seasonal-banner relative" v-if="hasNewSeasonalPage"></div>

    <div class="w-screen hidden relative offers-container lavish-special-offers-bg py-4 lg:py-2">
      <div class="flex justify-center items-center">
        <div class="flex flex-col items-center justify-center mobileL:flex-row">
          <img class="w-5/12 max-w-full h-auto py-4 mobileL:py-20 xl:py-36 mobileL:pr-10 mobileL:pl-20" src="../../assets/images/special-offers/glam-deal.png" alt="">
          <img class="w-7/12 max-w-full h-auto py-4 mobileL:py-8 xl:py-24 mobileL:pl-10 mobileL:pr-20" src="../../assets/images/special-offers/student-glam-deal.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="w-screen hidden offers-container lavish-special-offers-bg py-2">
      <div class="slider-container" id="slider-container1">
        <div class="slider" :style="{ transform: `translateX(-${activeIndex * 100}%)` }">
          <div class="slide">
            <div class="flex flex-col items-center justify-center mobileL:flex-row">
              <img class="w-5/12 max-w-full h-auto py-4 mobileL:py-20 xl:py-36 mobileL:pr-10 mobileL:pl-20" src="../../assets/images/special-offers/glam-deal.png" alt="">
              <img class="w-7/12 max-w-full h-auto py-4 mobileL:py-8 xl:py-24 mobileL:pl-10 mobileL:pr-20" src="../../assets/images/special-offers/student-glam-deal.jpg" alt="">
            </div>
          </div>
          <div class="slide">
            <div class="flex flex-col items-center justify-center mobileL:flex-row">
              <img class="w-5/12 max-w-full h-auto py-4 mobileL:py-20 xl:py-36 mobileL:pr-10 mobileL:pl-20" src="../../assets/images/special-offers/glam-deal.png" alt="">
              <img class="w-7/12 max-w-full h-auto py-4 mobileL:py-8 xl:py-24 mobileL:pl-10 mobileL:pr-20" src="../../assets/images/special-offers/student-glam-deal.jpg" alt="">
            </div>
          </div>
          <div class="slide">
            <div class="flex flex-col items-center justify-center mobileL:flex-row">
              <img class="w-5/12 max-w-full h-auto py-4 mobileL:py-20 xl:py-28 mobileL:pr-10 mobileL:pl-20" src="../../assets/images/special-offers/glam-deal.png" alt="">
              <img class="w-7/12 max-w-full h-auto py-4 mobileL:py-8 xl:py-16 mobileL:pl-10 mobileL:pr-20" src="../../assets/images/special-offers/student-glam-deal.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="slide-indicators">
        <button v-for="(slide, index) in slides" :key="index" @click="selectSlide(index)" :class="{ active: activeIndex === index }">
          <img class="w-6 mobileL:w-8 lg:w-10 mb-4 mobileL:mb-10 lg:mb-16" v-if="activeIndex === index" src="../../assets/images/special-offers/shine.png" alt="Selected Indicator" />
          <img class="w-6 mobileL:w-8 lg:w-10 mb-4 mobileL:mb-10 lg:mb-16" v-else src="../../assets/images/special-offers/shine 2.png" alt="Unselected Indicator" />
        </button>
      </div>
    </div>
    <div class="w-full absolute block z-20">
      <Footer/>
    </div>
  </div>
</template>
  
<script>
import Header from '../../components/navigation/LaybareHeader.vue'
import Footer from '../../components/footer/LavishFooter.vue'

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      activeIndex: 0,
      intervalDuration: 3000,
      lastNumber: 2,
      slides: [0, 1, 2],
      hasNewSeasonalPage: true,
    };
  },
  mounted() {
    this.startSlideShow();
  },
  methods: {
    startSlideShow() {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, this.intervalDuration);
    },
    stopSlideShow() {
      clearInterval(this.intervalId);
    },
    nextSlide() {
      if (this.activeIndex < this.lastNumber) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
      this.restartSlideShow();
    },
    prevSlide() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.lastNumber;
      }
      this.restartSlideShow();
    },
    selectSlide(index) {
      this.activeIndex = index;
      this.restartSlideShow();
    },
    restartSlideShow() {
      this.stopSlideShow();
      this.startSlideShow();
    }
  },
  beforeDestroy() {
    this.stopSlideShow();
  }
};
</script>
  
<style scoped>
  .offers-section-container {
    height: 700px;
  }
  
  .ll-seasonal-banner{
    background: url('../../assets/images/services-page/seasonal-banners/LLS_DEC_BANNER.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .offers-container {
    height: auto;
  }
  
  .space-top {
    margin-top: 90px;
  }

  .offers-section-bg{
    background: url('../../assets/images/services-page/lavish-services.png') no-repeat;
    background-size: cover;
  }

  .slide-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .slide-indicators button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin: 0 5px;
  }
  
  @media (max-width: 480px) {
    .space-top {
      margin-top: 60px;
    }
    .offers-section-container {
      height: 256px;
    }

    .ll-seasonal-banner{
    background: url('../../assets/images/services-page/seasonal-banners/LLS_Nov_Mobile.jpg') no-repeat;
    background-size: 100% 100%;
    }


    .slide-indicators button {
      padding: 0px;
      margin: 0 5px;
    }
  }
  
  .lavish-special-offers-banner {
    background: url('../../assets/images/special-offers/special-offers-banner-bg.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .lavish-special-offers-bg {
    background: url('../../assets/images/special-offers/special-offers-bg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }

  .lash-sin-bg {
    background: radial-gradient(#ED7A97 -20%, #fef6ef 70%);
    border-radius: 10px;
  }
  
  @media (min-width: 1280px) {
      .space-top {
        margin-top: 100px;
      }
      .offers-section-container {
        height: calc(100vh - 100px);
      } 
  }
  
  @media (min-width: 1536px) {
      .space-top {
        margin-top: 128px;
      }
  
      .offers-section-container {
        height: 110vh;
      }
  }

  .slider-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .slider {
    display: flex;
    transition: transform 0.5s ease;
  }

  .slide {
    flex: 0 0 100%;
  }
</style>